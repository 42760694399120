import Mousetrap from 'mousetrap'
import { Howl, Howler } from 'howler/src/howler.core'

// Hydrate the number of years experience
const thisYear = new Date().getFullYear()
const careerStart = new Date('2015-01-01').getFullYear()
const yearsExperience = `${thisYear > careerStart ? thisYear - careerStart : 6}+`;
document.getElementById('years_exp').innerText = yearsExperience;

let soundFile = new URL("../static/files/easter-egg-coin.wav", import.meta.url)
const sound = new Howl({
    src: [soundFile],
    html5: true
});
sound.volume(0.4);

// combinations
Mousetrap.bind('command+option+1', function () {
    toggleGame();
    return false;
});
// combinations
Mousetrap.bind('ctrl+option+1', function () {
    toggleGame();
    return false;
});


Mousetrap.bind('space', function (e) {
    if (e.preventDefault) {
        e.preventDefault();
    } else {
        // internet explorer
        e.returnValue = false;
    }
});



function runtime() {
R=1152,I=1134,O=36,T=0,G=[],a=[],m=[],e=[],s=32,H='innerHTML',N=setTimeout,o=(
i,b,c)=>i.substring(b,c),onkeydown=e=>V('1----0-2'[e.keyCode-s]),ontouchstart=
t=>V(W(t.changedTouches.item(0).screenX/screen.width*3)),Y=(m,a)=>{a.map(i=>{h=
o(h,0,i)+m+o(h,i+1,f.length)})},C=0,L=0;M=_=>(W=Math.floor)(Math.random()*_);Q=(
P,b)=>Array(b+1).join(P),f=Q(' ',R),p=3,N(l='A=A.map(j=>((j+=O)>R)?M(O):j==I&&\
L&&p--&&j||j);v()&&N(l,S)',S=64);for(A=[];C<R;)C=A[A.length-1]||0,A.push(C+M(s)+
S);P=['~(t=','.indexOf(k))?(n[H]=(++T+"").fontcolor("E35252"),','.splice(t,1)\
):'],i=':S9S08W5W06S5_04T8S3R4T02T;Q<S01SJR02RKR03RKR04R7/5/5/7R05R7/5/5/4S\
06R7/5/4S1S07S6/5Z08S1Z3T09U:S0<S6S0?V0AR'.split(0),N(g='j=[];while(k=G.pop\
()){k-=O,eval(P.join("e")+P.join("m")+"(k>0)?j.push(k):0");if(~(z=a.indexOf(\
k)))a.splice(z,1)}G=j;q();v()&&N(g,s)',s),q=_=>{h=f,D=[A,[I],e,m,a,G];for(F in J
='*A#\\ |')Y(J[F],D[F]);for(_=O+1,z=O;z<R/O*_;z+=_)h=o(h,0,z)+'\n'+o(h,z);b[H]=
!v()?'win!':h,K[H]=Q('A',p>0?p:(T=0,d(),p=3))},d=_=>{e=[],m=[],a=[],r=3*O;for(c
of i){w=r;for(u in c){if((U=c[u])=='/')m.push(w),x=1;else{x=U.charCodeAt(0)-48,E
=x<s?'a':(x-=s)&&'e';for(y=w;y<w+x;y++)+u&&eval(E+'.push(y)')}w+=x}r+=O}},V=e=>
N(['I=Math.max(R-36,I-1)','G.push(I)', 'I=Math.min(R-1,I+1)'][e],q(),L=1),v=_=>
T!=150;q(d()) // Brandon Freitag, (c) 2017, freitagbr.github.io/ritopls
}

function toggleGame() {
    sound.play();
    const treeElem = document.getElementById("tree-output");
    const oElem = document.getElementById("game");
    if (oElem.classList.contains('hidden')) {
        treeElem.classList.add('hidden')
        runtime();
        oElem.classList.remove('hidden')
        oElem.classList.remove('absolute')
    } else {
        treeElem.classList.toggle('hidden')
        oElem.classList.toggle('hidden')
        N = () => {}
    }
}

document.getElementById('egg').addEventListener("click", toggleGame);
